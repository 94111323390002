import { Route } from 'components/ui/route'
import { SeoTags } from 'components/seoTags'
import { HomePage } from 'components/home/home'
import { SEOManagerApi } from 'utils/actions'

export default function Home({ SEOData = null }) {
    return (
        <Route>
            <SeoTags
                title={SEOData?.Title}
                description={SEOData?.Description}
                canonicalPath={SEOData?.Canonical}
                noIndex={SEOData?.Bots}
            />

            <HomePage />
        </Route>
    )
}

export const getStaticProps = async () => {
    try {
        const responseSEO = await fetch(`${SEOManagerApi}`)
        const SEOData = await responseSEO.json()
        return { props: { SEOData } }
    } catch (error) {
        console.error(error)
        return { props: { SEOData: null } }
    }
}
